import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loader from "../assets/001 EN.gif";
import CircularProgress from "@mui/material/CircularProgress";
// import { urlToDataUrl } from "../components/convertImgUri";

function EditSetting() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const [theme, setTheme] = useState("");
  const [imagee, setImagee] = useState(null);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorTitleAr, setErrorTitleAr] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [errorDescAr, setErrorDescAr] = useState("");
  const [fullData, setFullData] = useState({});
  const [openErr, setOpenErr] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [desc, setDesc] = useState("");
  const [descAr, setDescAr] = useState("");
  const [request, setRequest] = useState(false);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get(`/modules/setting/${prodid[prodid.length - 1]}`)
          .then((res) => {
            setTitle(res.data.data?.title);
            setTitleAr(res.data.data?.title_ar);
            setDesc(res.data.data?.content);
            setDescAr(res.data.data?.content_ar);
            setFullData({
              ...fullData,
              title: {
                ar: res.data.data?.title_ar,
                en: res.data.data?.title,
              },
              content: {
                ar: res.data.data?.content_ar,
                en: res.data.data?.content,
              },
              // icon: res.data.data?.icon,
            })
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
      });
  }, []);
  const handleCloseErr = () => {
    setOpenErr(false);
  };
  function handleDisable() {
    if (
      (fullData?.title?.ar &&
        fullData?.title?.en &&
        fullData?.content?.en &&
        fullData?.content?.ar) ||
      request
    )
      return false;
    else return true;
  }
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className=" container px-5">
            <div className="">
              <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
                Edit Setting
              </h2>
            </div>
            <div className=" d-flex justify-content-between mb-5">
              <div className="w-100 text-center px-5">
                <InputLabel
                  style={{
                    textAlign: "start",
                    marginBottom: 14,
                    fontSize: 20,
                    color: "grey",
                  }}
                >
                  English Title
                </InputLabel>
                <TextField
                  label="Add Title"
                  variant="outlined"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  error={errorTitle}
                  value={title}
                  // helperText={errorTitle}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    if (e.target.value === "") {
                      setErrorTitle("Enter title");
                    } else if (e.target.value.length < 2) {
                      setErrorTitle("Title length must be at least 2");
                    } else {
                      setErrorTitle("");
                      setFullData({
                        ...fullData,
                        title: {
                          ...fullData.title,
                          en: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {errorTitle && (
                  <div
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: "12px" }}
                  >
                    {errorTitle}
                  </div>
                )}
              </div>
              <div className="w-100 text-center px-5">
                <InputLabel
                  style={{
                    textAlign: "start",
                    marginBottom: 14,
                    fontSize: 20,
                    color: "grey",
                  }}
                >
                  Arabic Title
                </InputLabel>
                <TextField
                  label="Add Title"
                  variant="outlined"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  error={errorTitleAr}
                  value={titleAr}
                  // helperText={errorTitleAr}
                  onChange={(e) => {
                    setTitleAr(e.target.value);
                    if (e.target.value === "") {
                      setErrorTitleAr("Enter title");
                    } else if (e.target.value.length < 2) {
                      setErrorTitleAr("Title length must be at least 2");
                    } else {
                      setErrorTitleAr("");
                      setFullData({
                        ...fullData,
                        title: {
                          ...fullData.title,
                          ar: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {errorTitleAr && (
                  <div
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: "12px" }}
                  >
                    {errorTitleAr}
                  </div>
                )}
              </div>
            </div>
            <div className=" d-flex justify-content-between mb-5 gap-5 px-5">
              <div className="w-100 text-center">
                <InputLabel
                  style={{
                    textAlign: "start",
                    marginBottom: 14,
                    fontSize: 20,
                    color: "grey",
                  }}
                >
                  English Content
                </InputLabel>
                <textarea
                  value={desc}
                  className="form-control"
                  label="Content"
                  sx={{ backgroundColor: "white" }}
                  style={{ width: "100%", height: 50 }}
                  placeholder="Add Content"
                  onChange={(e) => {
                    setDesc(e.target.value);
                    if (e.target.value === "")
                      setErrorDesc("Enter Content");
                    else if (e.target.value.length < 3) {
                      setErrorDesc("Content length must be at least 3");
                    } else {
                      setErrorDesc("");
                      setFullData({
                        ...fullData,
                        content: {
                          ...fullData.content,
                          en: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {errorDesc && (
                  <div
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: "12px" }}
                  >
                    {errorDesc}
                  </div>
                )}
              </div>
              <div className="w-100 text-center">
                <InputLabel
                  style={{
                    textAlign: "start",
                    marginBottom: 14,
                    fontSize: 20,
                    color: "grey",
                  }}
                >
                  Arabic Content
                </InputLabel>
                <textarea
                  value={descAr}
                  className="form-control"
                  label="Content"
                  sx={{ backgroundColor: "white" }}
                  style={{ width: "100%", height: 50 }}
                  placeholder="Add Content"
                  onChange={(e) => {
                    setDescAr(e.target.value);
                    if (e.target.value === "")
                      setErrorDescAr("Enter Content");
                    else if (e.target.value.length < 3) {
                      setErrorDescAr("Content length must be at least 3");
                    } else {
                      setErrorDescAr("");
                      setFullData({
                        ...fullData,
                        content: {
                          ...fullData.content,
                          ar: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {errorDescAr && (
                  <div
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: "12px" }}
                  >
                    {errorDescAr}
                  </div>
                )}
              </div>
            </div>
            <div
              className=" d-flex justify-content-end gap-5"
              style={{ marginTop: 100 }}
            >
              {/* <Button
                variant="outlined"
                style={{ width: 100 }}
                onClick={() => {}}
              >
                <div className=" d-flex justify-content-center w-100 align-items-center">
                  <span>Cancel</span>
                </div>
              </Button> */}
              <Button
                disabled={handleDisable() || request}
                variant="contained"
                style={{ width: 100 }}
                onClick={() => {
                  setRequest(true);
                  if (
                    errorDescAr === "" &&
                    errorTitle === "" &&
                    errorTitleAr === "" &&
                    errorDesc === ""
                  ) {
                    const form = new FormData();
                    for (let dataKey in fullData) {
                      if (dataKey === "title") {
                        for (let previewKey in fullData[dataKey]) {
                          form.append(
                            `title[${previewKey}]`,
                            fullData[dataKey][previewKey]
                          );
                        }
                      } else if (dataKey === "description") {
                        for (let previewKey in fullData[dataKey]) {
                          form.append(
                            `description[${previewKey}]`,
                            fullData[dataKey][previewKey]
                          );
                        }
                      } else {
                        form.append(dataKey, fullData[dataKey]);
                      }
                    }
                    form.append("_method", "PATCH");
                    axios.defaults.withCredentials = true;
                    axios.defaults.withXSRFToken = true;
                    axios
                      .get("https://api.2ladue.com/sanctum/csrf-cookie")
                      .then((response) => {
                        axiosInstance
                          .post(
                            `modules/setting/${
                              prodid[prodid.length - 1]
                            }`,
                            form,
                            {
                              xsrfHeaderName: "X-XSRF-TOKEN", // change the name of the header to "X-XSRF-TOKEN" and it should works
                              withCredentials: true,
                            }
                          )
                          .then((res) => {
                            swal("Setting edited successfully");
                            setRequest(false);
                            nav("/settings");
                          })
                          .catch((err) => {
                            console.log(err);
                            swal("Something went wrong");
                            setRequest(false);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        swal("Something went wrong");
                        setRequest(false);
                      });
                  } else {
                    setOpenErr(true);
                  }
                }}
              >
                <div className=" d-flex justify-content-center w-100 align-items-center">
                  <span>
                    {request ? (
                      <CircularProgress size={14} sx={{ color: "white" }} />
                    ) : (
                      "Edit"
                    )}
                  </span>
                </div>
              </Button>
            </div>
          </div>
          <Dialog
            open={openErr}
            // TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="sm"
            onClose={handleCloseErr}
            aria-describedby="alert-dialog-slide-description1"
          >
            <DialogTitle>
              <p>{"Missing Data"}</p>
              <Divider sx={{ borderColor: "black" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description1">
                You have left empty fields
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErr}>Back</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default EditSetting;
