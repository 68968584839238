import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/inputStyle.css";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";

function PriceCateStoch({
  setFullData,
  setStage,
  stage,
  title,
  setErrorCate,
  errorCate,
  fullData,
  errorPrice,
  errorQty,
  setErrorQty,
  setErrorPrice,
  setErrorType,
  errorType,
  errorLimit,
  setErrorLimit,
  setErrorLimitP,
  errorLimitP,
  setErrorDisplayOrder,
  errorDisplayOrder,
  categories,
  cate,
  setCate,
  type,
  setType,
  price,
  setPrice,
  limitP,
  setLimitP,
  limitVal,
  setLimitVal,
  qty,
  setQty,
  displayOrderVal,
  setDisplayOrderVal,
}) {
  const [open, setOpen] = React.useState(false);
  const [openT, setOpenT] = useState(false);
  const [openL, setOpenL] = useState(false);
  const [val, setVal] = useState(null);
  const [valT, setValT] = useState(null);
  const [valL, setValL] = useState(null);
  const [numVal, setNumVal] = useState(1);
  const [qtyVal, setQtyVal] = useState(1);
  const [limit, setLimit] = useState(1);
  const [displayOrder, setDisplayOrder] = useState(1);
  useEffect(()=>{
    if(cate) setVal(cate)
    if(type) setValT(type)
    if(limitP) setValL(limitP)
    if(price) setNumVal(price)
    if(qty) setQtyVal(qty)
    if(limitVal) setLimit(limitVal)
    if(displayOrderVal) setDisplayOrderVal(displayOrderVal)

  }, [])
  const blue = {
    100: "#daecff",
    200: "#b6daff",
    300: "#66b2ff",
    400: "#3399ff",
    500: "#007fff",
    600: "#0072e5",
    700: "#0059B2",
    800: "#004c99",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        line-height: 1.5;
        border: 1px solid;
        border-radius: 999px;
        border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
        background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
        color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
        width: 32px;
        height: 32px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
        &:hover {
          cursor: pointer;
          background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
          border-color: ${
            theme.palette.mode === "dark" ? blue[500] : blue[400]
          };
          color: ${grey[50]};
        }
      
        &:focus-visible {
          outline: 0;
        }
      
        &.increment {
          order: 1;
        }
      `
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseT = () => {
    setOpenT(false);
  };

  const handleOpenT = () => {
    setOpenT(true);
  };

  const handleCloseL = () => {
    setOpenL(false);
  };

  const handleOpenL = () => {
    setOpenL(true);
  };

  return (
    <>
      <div className="">
        <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
          {title}
        </h2>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="inputs d-flex justify-content-between mb-5">
          <div className="w-100 text-center px-5 mt-1">
            <InputLabel
              style={{
                textAlign: "start",
                marginBottom: 14,
                fontSize: 20,
                color: "grey",
              }}
            >
              Choose Category
            </InputLabel>
            <Select
              fullWidth
              sx={{ backgroundColor: "white" }}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={val}
              onChange={(e) => {
                if (e.target.value === "") {
                  setErrorCate("Choose category");
                } else {
                  setVal(e.target.value);
                  setFullData({
                    ...fullData,
                    product_category_id: e.target.value,
                  });
                  setErrorCate("");
                }
              }}
            >
              {categories?.map((auc, idx) => {
                return <MenuItem key={idx} value={auc?.id}>{auc?.title}</MenuItem>;
              })}
            </Select>
            {errorCate && (
              <div
                className="text-danger text-start ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorCate}
              </div>
            )}
          </div>
          <div className="w-100 text-center px-5 mt-1">
            <InputLabel
              style={{
                textAlign: "start",
                marginBottom: 14,
                fontSize: 20,
                color: "grey",
              }}
            >
              Choose Type
            </InputLabel>
            <Select
              fullWidth
              sx={{ backgroundColor: "white" }}
              open={openT}
              onClose={handleCloseT}
              onOpen={handleOpenT}
              value={valT}
              onChange={(e) => {
                if (e.target.value === "") {
                  setErrorType("Choose category");
                } else {
                  setValT(e.target.value);
                  setFullData({
                    ...fullData,
                    type: e.target.value,
                  });
                  setErrorType("");
                }
              }}
            >
              <MenuItem value={"digital"}>Digital</MenuItem>
              <MenuItem value={"physical"}>Physical</MenuItem>
            </Select>
            {errorType && (
              <div
                className="text-danger text-start ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorType}
              </div>
            )}
          </div>
          <div className="w-100 text-center px-5 mt-1">
            <InputLabel
              style={{
                textAlign: "start",
                marginBottom: 14,
                fontSize: 20,
                color: "grey",
              }}
            >
              Purchase Limit Period
            </InputLabel>
            <Select
              fullWidth
              sx={{ backgroundColor: "white" }}
              open={openL}
              onClose={handleCloseL}
              onOpen={handleOpenL}
              value={valL}
              onChange={(e) => {
                if (e.target.value === "") {
                  setErrorType("Choose category");
                } else {
                  setValL(e.target.value);
                  setFullData({
                    ...fullData,
                    direct_purchase_limit_period: e.target.value,
                  });
                  setErrorLimitP("");
                }
              }}
            >
              <MenuItem value={"daily"}>Daily</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
            </Select>
            {errorLimitP && (
              <div
                className="text-danger text-start ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorLimitP}
              </div>
            )}
          </div>
        </div>
        <div className="inputs d-flex justify-content-evenly mb-5">
          <div className="w-100">
            <div className="w-100 text-center px-5">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                  width: "100%",
                  textAlignLast: "center",
                }}
              >
                Add Price
              </InputLabel>
              <div className=" d-flex justify-content-center align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.price > 1) {
                      setFullData({
                        ...fullData,
                        price: fullData.price - 1,
                      });
                      setNumVal(fullData.price - 1);
                      setErrorPrice("");
                    } else if (fullData?.price === 0 || !fullData?.price) {
                      setFullData({
                        ...fullData,
                        price: numVal,
                      });
                      setNumVal(1);
                    } else {
                      setFullData({
                        ...fullData,
                        price: numVal,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={numVal}
                  onChange={(e) => {
                    setNumVal(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        price: Number(e.target.value),
                      });
                      setErrorPrice("");
                    } else {
                      setErrorPrice("Enter price");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.price < 999999) {
                      setFullData({
                        ...fullData,
                        price: fullData.price + 1,
                      });
                      setNumVal(fullData.price + 1);
                      setErrorPrice("");
                    } else if (fullData?.price === 1000000) {
                      setFullData({
                        ...fullData,
                        price: 999999,
                      });
                      setNumVal(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        price: numVal,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorPrice && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorPrice}
              </div>
            )}
          </div>
          <div className="w-100">
            <div className="w-100 text-center px-5">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                  width: "100%",
                  textAlignLast: "center",
                }}
              >
                Quantity in stock
              </InputLabel>
              <div className=" d-flex justify-content-center align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.quantity > 1) {
                      setFullData({
                        ...fullData,
                        quantity: fullData.quantity - 1,
                        current_quantity: fullData.quantity - 1,
                        main_quantity: fullData.quantity - 1,
                      });
                      setQtyVal(fullData.quantity - 1);
                      setErrorQty("");
                    } else if (
                      fullData?.quantity === 0 ||
                      !fullData?.quantity ||
                      qtyVal === 0
                    ) {
                      setFullData({
                        ...fullData,
                        quantity: 1,
                        current_quantity: 1,
                        main_quantity: 1,
                      });
                      setQtyVal(1);
                    } else {
                      setFullData({
                        ...fullData,
                        quantity: qtyVal,
                        current_quantity: qtyVal,
                        main_quantity: qtyVal,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={qtyVal}
                  onChange={(e) => {
                    setQtyVal(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        quantity: Number(e.target.value),
                        current_quantity: Number(e.target.value),
                        main_quantity: Number(e.target.value),
                      });
                      setErrorQty("");
                    } else {
                      setErrorQty("Enter quantity");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.quantity < 999999) {
                      setFullData({
                        ...fullData,
                        quantity: fullData.quantity + 1,
                        current_quantity: fullData.quantity + 1,
                        main_quantity: fullData.quantity + 1,
                      });
                      setQtyVal(fullData.quantity + 1);
                      setErrorQty("");
                    } else if (fullData?.quantity === 1000000) {
                      setFullData({
                        ...fullData,
                        quantity: 999999,
                        current_quantity: 999999,
                        main_quantity: 999999,
                      });
                      setQtyVal(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        quantity: qtyVal,
                        current_quantity: qtyVal,
                        main_quantity: qtyVal,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorQty && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorQty}
              </div>
            )}
          </div>
        </div>
        <div className="inputs d-flex justify-content-evenly mb-5">
          <div className="w-100">
            <div className="w-100 text-center px-5">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                  width: "100%",
                  textAlignLast: "center",
                }}
              >
                Direct Purchase Limit
              </InputLabel>
              <div className=" d-flex justify-content-center align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.direct_purchase_limit > 1) {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: fullData.direct_purchase_limit - 1,
                      });
                      setLimit(fullData.direct_purchase_limit - 1);
                      setErrorLimit("");
                    } else if (
                      fullData?.direct_purchase_limit === 0 ||
                      !fullData?.direct_purchase_limit
                    ) {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: limit,
                      });
                      setLimit(1);
                    } else {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: limit,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={limit}
                  onChange={(e) => {
                    setLimit(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: Number(e.target.value),
                      });
                      setErrorLimit("");
                    } else {
                      setErrorLimit("Enter direct purchase limit");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.direct_purchase_limit < 999999) {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: fullData.direct_purchase_limit + 1,
                      });
                      setLimit(fullData.direct_purchase_limit + 1);
                      setErrorLimit("");
                    } else if (fullData?.direct_purchase_limit === 1000000) {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: 999999,
                      });
                      setLimit(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        direct_purchase_limit: limit,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorLimit && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorLimit}
              </div>
            )}
          </div>
          <div className="w-100">
            <div className="w-100 text-center px-5">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                  width: "100%",
                  textAlignLast: "center",
                }}
              >
                Display Order
              </InputLabel>
              <div className=" d-flex justify-content-center align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.display_order > 1) {
                      setFullData({
                        ...fullData,
                        display_order: fullData.display_order - 1,
                      });
                      setDisplayOrder(fullData.display_order - 1);
                      setErrorDisplayOrder("");
                    } else if (
                      fullData?.display_order === 0 ||
                      !fullData?.display_order
                    ) {
                      setFullData({
                        ...fullData,
                        display_order: displayOrder,
                      });
                      setDisplayOrder(1);
                    } else {
                      setFullData({
                        ...fullData,
                        display_order: displayOrder,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={displayOrder}
                  onChange={(e) => {
                    setDisplayOrder(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        display_order: Number(e.target.value),
                      });
                      setErrorDisplayOrder("");
                    } else {
                      setErrorDisplayOrder("Enter display order");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.display_order < 999999) {
                      setFullData({
                        ...fullData,
                        display_order: fullData.display_order + 1,
                      });
                      setDisplayOrder(fullData.display_order + 1);
                      setErrorDisplayOrder("");
                    } else if (fullData?.display_order === 1000000) {
                      setFullData({
                        ...fullData,
                        display_order: 999999,
                      });
                      setDisplayOrder(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        display_order: displayOrder,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorDisplayOrder && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorDisplayOrder}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceCateStoch;
