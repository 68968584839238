import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/inputStyle.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";

function PriceQtyAdd({
  setFullData,
  title,
  fullData,
  setErrorPrice,
  errorPrice,
  errorQty,
  setErrorQty,
  errorStart,
  setErrorStart,
  errorEnd,
  setErrorEnd,
  setErrorProd,
  errorProd,
  products,
  price,
  setPrice,
}) {
  const [numVal, setNumVal] = useState(1);
  const [qtyVal, setQtyVal] = useState(1);
  const [openT, setOpenT] = useState(false);
  const [valT, setValT] = useState(null);
  const [str, setStr] = useState(null);
  const utc = new Date();
  useEffect(() => {
    setNumVal(price);
  }, []);
  const blue = {
    100: "#daecff",
    200: "#b6daff",
    300: "#66b2ff",
    400: "#3399ff",
    500: "#007fff",
    600: "#0072e5",
    700: "#0059B2",
    800: "#004c99",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
            font-family: 'IBM Plex Sans', sans-serif;
            font-size: 0.875rem;
            box-sizing: border-box;
            line-height: 1.5;
            border: 1px solid;
            border-radius: 999px;
            border-color: ${
              theme.palette.mode === "dark" ? grey[800] : grey[200]
            };
            background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
            color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
            width: 32px;
            height: 32px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 120ms;
            &:hover {
              cursor: pointer;
              background: ${
                theme.palette.mode === "dark" ? blue[700] : blue[500]
              };
              border-color: ${
                theme.palette.mode === "dark" ? blue[500] : blue[400]
              };
              color: ${grey[50]};
            }
          
            &:focus-visible {
              outline: 0;
            }
          
            &.increment {
              order: 1;
            }
          `
  );

  const handleCloseT = () => {
    setOpenT(false);
  };

  const handleOpenT = () => {
    setOpenT(true);
  };

  return (
    <>
      <div className="">
        <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
          {title}
        </h2>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="inputs-pack w-100 d-flex justify-content-between gap-5 mb-5">
          <div className="">
            <div className=" text-center ">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                  width: "100%",
                  textAlignLast: "center",
                }}
              >
                Add Price
              </InputLabel>
              <div className=" d-flex justify-content-center align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.price > 1) {
                      setFullData({
                        ...fullData,
                        price: fullData.price - 1,
                      });
                      setNumVal(fullData.price - 1);
                      setErrorPrice("");
                    } else if (fullData?.price === 0 || !fullData?.price) {
                      setFullData({
                        ...fullData,
                        price: numVal,
                      });
                      setNumVal(1);
                    } else {
                      setFullData({
                        ...fullData,
                        price: numVal,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={numVal}
                  onChange={(e) => {
                    setNumVal(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        price: Number(e.target.value),
                      });
                      setErrorPrice("");
                    } else {
                      setErrorPrice("Enter price");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.price < 999999) {
                      setFullData({
                        ...fullData,
                        price: fullData.price + 1,
                      });
                      setNumVal(fullData.price + 1);
                      setErrorPrice("");
                    } else if (fullData?.price === 1000000) {
                      setFullData({
                        ...fullData,
                        price: 999999,
                      });
                      setNumVal(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        price: numVal,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorPrice && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorPrice}
              </div>
            )}
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div className="selection text-center mt-1">
              <InputLabel
                style={{
                  textAlign: "center",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Choose Product
              </InputLabel>
              <Select
                fullWidth
                sx={{ backgroundColor: "white" }}
                open={openT}
                onClose={handleCloseT}
                onOpen={handleOpenT}
                value={valT}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setErrorProd("Choose product");
                  } else {
                    setValT(e.target.value);
                    setFullData({
                      ...fullData,
                      product_id: e.target.value,
                    });
                    setErrorProd("");
                  }
                }}
              >
                {products?.map((auc, idx) => {
                  return (
                    <MenuItem value={auc?.id}>
                      {auc?.title ? auc?.title : "Title"}
                    </MenuItem>
                  );
                })}
              </Select>
              {errorProd && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorProd}
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div className=" text-center">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                  width: "100%",
                  textAlignLast: "center",
                }}
              >
                Quantity
              </InputLabel>
              <div className=" d-flex justify-content-center align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.quantity > 1) {
                      setFullData({
                        ...fullData,
                        quantity: fullData.quantity - 1,
                      });
                      setQtyVal(fullData.quantity - 1);
                      setErrorQty("");
                    } else if (
                      fullData?.quantity === 0 ||
                      !fullData?.quantity ||
                      qtyVal === 0
                    ) {
                      setFullData({
                        ...fullData,
                        quantity: 1,
                      });
                      setQtyVal(1);
                    } else {
                      setFullData({
                        ...fullData,
                        quantity: qtyVal,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={qtyVal}
                  onChange={(e) => {
                    setQtyVal(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        quantity: Number(e.target.value),
                      });
                      setErrorQty("");
                    } else {
                      setErrorQty("Enter quantity");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.quantity < 999999) {
                      setFullData({
                        ...fullData,
                        quantity: fullData.quantity + 1,
                      });
                      setQtyVal(fullData.quantity + 1);
                      setErrorQty("");
                    } else if (fullData?.quantity === 1000000) {
                      setFullData({
                        ...fullData,
                        quantity: 999999,
                      });
                      setQtyVal(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        quantity: qtyVal,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorQty && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorQty}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="view-page d-flex justify-content-between px-5 mb-5">
        <div>
          <div
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            Choose Date Range
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ mb: 4 }} components={["MobileDatePicker"]}>
              <DemoItem label={<div style={{ color: "grey" }}>Start Date</div>}>
                <MobileDatePicker
                  sx={{ bgcolor: "white" }}
                  minDate={dayjs(utc)}
                  onChange={(e) => {
                    setFullData({
                      ...fullData,
                      dstart: `${e?.$y}-${e?.$M + 1}-${e?.$D}`,
                    });
                    setStr(e?.$d);
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ mb: 4 }} components={["MobileDatePicker"]}>
              <DemoItem label={<div style={{ color: "grey" }}>End Date</div>}>
                <MobileDatePicker
                  sx={{ bgcolor: "white" }}
                  minDate={str ? dayjs(str) : dayjs(utc)}
                  onChange={(e) => {
                    setFullData({
                      ...fullData,
                      dend: `${e?.$y}-${e?.$M + 1}-${e?.$D}`,
                    });
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div>
          <div
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            Choose Time Range
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ mb: 4 }} components={["MobileTimePicker"]}>
              <DemoItem label={<div style={{ color: "grey" }}>Start Time</div>}>
                <MobileTimePicker
                  sx={{ bgcolor: "white" }}
                  onChange={(e) => {
                    setFullData({
                      ...fullData,
                      tstart: `${e?.$H}:${e?.$m}`,
                    });
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MobileTimePicker"]}>
              <DemoItem label={<div style={{ color: "grey" }}>End Time</div>}>
                <MobileTimePicker
                  sx={{ bgcolor: "white" }}
                  minTime={
                    fullData?.tstart ? dayjs(fullData?.tstart) : dayjs(utc)
                  }
                  onChange={(e) => {
                    setFullData({
                      ...fullData,
                      tend: `${e?.$H}:${e?.$m}`,
                    });
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
}

export default PriceQtyAdd;
