import React, { useEffect, useState } from "react";
import customers from "../dummydata/customers.json";
import ServerTable from "../components/ServerTable";
import { customersCol } from "../components/columns";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import loader from "../assets/001 EN.gif";
import { axiosInstance } from "../config/axiosInstance";
import swal from 'sweetalert';
import axios from "axios";
import "../styles/inputStyle.css";

function Customers() {
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState(null)
  const [theme, setTheme] = useState("");
  const [type, setType] = useState("all")
  const [selected, setSelected] = useState(null);
  const [val, setVal] = useState("");
  const columns = customersCol(customerClick);
  function customerClick(id) {
    nav(`/customer/${id}`);
  }
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
    axiosInstance.get('/modules/client').then((res)=>{
      setCustomers(res.data.data.data)
      setSelected(res.data.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }).catch((err)=>{
      console.log(err);
      if(err.response?.status !== 404 && err.response?.data?.message !== "No Data Found"){
        swal("Something went wrong")
      }
      setLoading(false);
    })
  })
  .catch((err) => {
    console.log(err);
    setLoading(false);
  });
  }, []);
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className="container">
            <ServerTable
              title="Customers"
              type={type}
              setType={setType}
              data={selected}
              columns={columns}
              search={
                <div className="search-box">
                  <button className="btn-search">
                    <SearchIcon />
                  </button>
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Search"
                    value={val}
                    onChange={(e) => {
                      setVal(e.target.value);
                      if (e.target.value === "") {
                        setSelected(customers);
                      } else {
                        const filtered = customers?.filter((item, index) => {
                          return item.name.toLowerCase().includes(e.target.value);
                        });
                        setSelected(filtered);
                      }
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Customers;
