import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import loader from "../assets/001 EN.gif";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { settingsCol } from "../components/columns";
import { useNavigate } from "react-router-dom";
import ServerTable from "../components/ServerTable";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import logoALt from '../assets/prod.png';

function Settings() {
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(null);
  const [value, setValue] = useState(0);
  const [val, setVal] = useState("");
  const [selected, setSelected] = useState(null);
  const [settings, setSettings] = useState(null);
  const [global, setGlobal] = useState(null)
  const [index, setIndex] = useState(0)
  const column = settingsCol(settingsClick, editSettings)
  function settingsClick(id) {
    nav(`/setting/${id}`);
  }
  function editSettings(id) {
    nav(`/editsetting/${id}`);
  }
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get(`modules/setting`)
          .then((res) => {
            setSettings(res.data.data?.data)
            setSelected(res.data.data?.data)
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.status !== 404 && err.response?.data?.message !== "No Data Found"){
              swal("Something went wrong");
            }
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get(`modules/global-setting/1`)
          .then((res) => {
            setGlobal(res.data.data)
            setLoading(false);
            // setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            swal("Somthing went wrong");
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ width: "100%" }}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className="container">
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                height: 224,
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab onClick={()=>setIndex(0)} label={<div style={{color: index === 0 ? "#2e8bf2" : "grey"}}>Application Settings</div>} {...a11yProps(0)} />
                <Tab onClick={()=>setIndex(1)} label={<div style={{color: index === 1 ? "#2e8bf2" : "grey"}}>Global Settings</div>} {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <ServerTable
                  title="Application Settings"
                  type={"all"}
                  // setType={setType}
                  data={selected}
                  columns={column}
                  search={
                    <div className="search-box">
                      <button className="btn-search">
                        <SearchIcon />
                      </button>
                      <input
                        type="text"
                        className="input-search"
                        placeholder="Search"
                        value={val}
                        onChange={(e) => {
                          setVal(e.target.value);
                          if (e.target.value === "") {
                            setSelected(settings);
                          } else {
                            const filtered = settings?.filter((item, index) => {
                              return item.title.toLowerCase().includes(e.target.value);
                            });
                            setSelected(filtered);
                          }
                        }}
                      />
                    </div>
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box sx={{ width: "100%" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <div className=" d-flex justify-content-between flex-column gap-4">
                        <div className="text-secondary fw-semibold">App Name</div>
                        <div style={{ color: theme === "Night" ? "white" : "black" }}>
                          {global?.appname ? global?.appname : "-"}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className=" d-flex justify-content-between flex-column gap-4">
                        <div className="text-secondary fw-semibold">App Logo</div>
                        <div
                          className=" d-flex justify-content-start"
                          style={{ height: "100px", overflow: "hidden" }}
                        >
                          <img alt="sli" src={global?.logo ? global?.logo : logoALt} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
          </div>
        </div>
      )}
    </>
  );
}

export default Settings;
