import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/inputStyle.css";

function BidDuration({
  fullData,
  setFullData,
  errorBid,
  errorType,
  errorTotal,
  errorDuration,
  errorDurationType,
  setErrorBid,
  setErrorType,
  setErrorTotal,
  setErrorDuration,
  setErrorDurationType,
  title,
  pack,
  dur
}) {
  const [numVal, setNumVal] = useState(1);
  const [numTot, setNumTot] = useState(1);
  const [numDur, setNumDur] = useState(1);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(null);
  const [openT, setOpenT] = useState(false);
  const [valT, setValT] = useState(null);

  useEffect(() => {
    if (pack) {
      setNumVal(pack?.bid_value)
      setNumTot(pack?.total_bids)
      setNumDur(pack?.duration)
    }
    if (dur) {
      setVal(dur)
    }
  }, [])

  const blue = {
    100: "#daecff",
    200: "#b6daff",
    300: "#66b2ff",
    400: "#3399ff",
    500: "#007fff",
    600: "#0072e5",
    700: "#0059B2",
    800: "#004c99",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
            font-family: 'IBM Plex Sans', sans-serif;
            font-size: 0.875rem;
            box-sizing: border-box;
            line-height: 1.5;
            border: 1px solid;
            border-radius: 999px;
            border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
            background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
            color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
            width: 32px;
            height: 32px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 120ms;
            &:hover {
              cursor: pointer;
              background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]
      };
              border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]
      };
              color: ${grey[50]};
            }
          
            &:focus-visible {
              outline: 0;
            }
          
            &.increment {
              order: 1;
            }
          `
  );
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseT = () => {
    setOpenT(false);
  };

  const handleOpenT = () => {
    setOpenT(true);
  };

  return (
    <>
      <div className="">
        <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
          {title}
        </h2>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="inputs-pack d-flex justify-content-between gap-5 mb-5">
          <div>
            <div className="w-100 text-center">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Add Bid Value
              </InputLabel>
              {/* <NumberInput aria-label="Quantity Input" min={1} max={999999} /> */}
              <div className=" d-flex justify-content-between align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.bid_value > 1 && fullData?.bid_value < 1000000) {
                      setFullData({
                        ...fullData,
                        bid_value: fullData.bid_value - 1,
                      });
                      setNumVal(fullData.bid_value - 1);
                      setErrorBid("")
                    } else if (fullData?.bid_value === 0 || !fullData?.bid_value) {
                      setFullData({
                        ...fullData,
                        bid_value: numVal,
                      });
                      setNumVal(1);
                    } else {
                      setFullData({
                        ...fullData,
                        bid_value: numVal,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={numVal}
                  onChange={(e) => {
                    setNumVal(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        bid_value: Number(e.target.value),
                      });
                      setErrorBid("");
                    } else {
                      setErrorBid("Enter bid value");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.bid_value < 1000000 && fullData?.bid_value > 0) {
                      setFullData({
                        ...fullData,
                        bid_value: fullData.bid_value + 1,
                      });
                      setNumVal(fullData.bid_value + 1);
                      setErrorBid("")
                    } else if (fullData?.bid_value === 1000000) {
                      setFullData({
                        ...fullData,
                        bid_value: 999999,
                      });
                      setNumVal(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        bid_value: numVal,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorBid && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorBid}
              </div>
            )}
          </div>
          <div>
            <div className="w-100 text-center">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Add Total Bids
              </InputLabel>
              {/* <NumberInput aria-label="Quantity Input" min={1} max={999999} /> */}
              <div className=" d-flex justify-content-between align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.total_bids > 1) {
                      setFullData({
                        ...fullData,
                        total_bids: fullData.total_bids - 1,
                      });
                      setNumTot(fullData.total_bids - 1);
                      setErrorTotal("")
                    } else if (
                      fullData?.total_bids === 0 ||
                      !fullData?.total_bids
                    ) {
                      setFullData({
                        ...fullData,
                        total_bids: numTot,
                      });
                      setNumTot(1);
                    } else {
                      setFullData({
                        ...fullData,
                        total_bids: numTot,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={numTot}
                  onChange={(e) => {
                    setNumTot(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        total_bids: Number(e.target.value),
                      });
                      setErrorTotal("");
                    } else {
                      setErrorTotal("Enter total bids");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.total_bids < 999999) {
                      setFullData({
                        ...fullData,
                        total_bids: fullData.total_bids + 1,
                      });
                      setNumTot(fullData.total_bids + 1);
                      setErrorTotal("")
                    } else if (fullData?.total_bids === 1000000) {
                      setFullData({
                        ...fullData,
                        total_bids: 999999,
                      });
                      setNumTot(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        total_bids: numTot,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorTotal && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorTotal}
              </div>
            )}
          </div>
          <div>
            <div className="w-100 text-center">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Add Duration
              </InputLabel>
              {/* <NumberInput aria-label="Quantity Input" min={1} max={999999} /> */}
              <div className=" d-flex justify-content-between align-items-center">
                <StyledButton
                  onClick={() => {
                    if (fullData?.duration > 1) {
                      setFullData({
                        ...fullData,
                        duration: fullData.duration - 1,
                      });
                      setNumDur(fullData.duration - 1);
                      setErrorDuration("")
                    } else if (fullData?.duration === 0 || !fullData?.duration) {
                      setFullData({
                        ...fullData,
                        duration: numDur,
                      });
                      setNumDur(1);
                    } else {
                      setFullData({
                        ...fullData,
                        duration: numDur,
                      });
                    }
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </StyledButton>
                <input
                  type="number"
                  className="inputFieldStyle"
                  value={numDur}
                  onChange={(e) => {
                    setNumDur(Number(e.target.value));
                    if (
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < 1000000
                    ) {
                      setFullData({
                        ...fullData,
                        duration: Number(e.target.value),
                      });
                      setErrorDuration("");
                    } else {
                      setErrorDuration("Enter duration");
                    }
                  }}
                />
                <StyledButton
                  onClick={() => {
                    if (fullData?.duration < 999999) {
                      setFullData({
                        ...fullData,
                        duration: fullData.duration + 1,
                      });
                      setNumDur(fullData.duration + 1);
                      setErrorDuration("")
                    } else if (fullData?.duration === 1000000) {
                      setFullData({
                        ...fullData,
                        duration: 999999,
                      });
                      setNumDur(999999);
                    } else {
                      setFullData({
                        ...fullData,
                        duration: numDur,
                      });
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                </StyledButton>
              </div>
            </div>
            {errorDuration && (
              <div
                className="text-danger text-center ms-3 my-1"
                style={{ fontSize: "12px" }}
              >
                {errorDuration}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="view-page d-flex justify-content-between gap-5 mb-5">
        <div className="w-100 text-center">
          <InputLabel
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            Choose Duration Type
          </InputLabel>
          <Select
            fullWidth
            sx={{ backgroundColor: "white" }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={val}
            onChange={(e) => {
              if (e.target.value === "") {
                setErrorDurationType("Choose duration type");
              } else {
                setVal(e.target.value);
                setFullData({
                  ...fullData,
                  duration_type: e.target.value,
                });
                setErrorDurationType("");
              }
            }}
          >
            <MenuItem value={"day"}>Day</MenuItem>
            <MenuItem value={"week"}>Week</MenuItem>
            <MenuItem value={"month"}>Month</MenuItem>
            <MenuItem value={"year"}>Year</MenuItem>
          </Select>
          {errorDurationType && (
            <div
              className="text-danger text-start ms-3 my-1"
              style={{ fontSize: "12px" }}
            >
              {errorDurationType}
            </div>
          )}
        </div>
        <div className="w-100 text-center">
          <InputLabel
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            Choose Bid Type
          </InputLabel>
          <Select
            fullWidth
            sx={{ backgroundColor: "white" }}
            open={openT}
            onClose={handleCloseT}
            onOpen={handleOpenT}
            value={valT}
            onChange={(e) => {
              if (e.target.value === "") {
                setErrorType("Choose bid type");
              } else {
                setValT(e.target.value);
                setFullData({
                  ...fullData,
                  type: e.target.value,
                });
                setErrorType("");
              }
            }}
          >
            <MenuItem value={"free"}>Free</MenuItem>
            <MenuItem value={"gift"}>Gift</MenuItem>
            <MenuItem value={"purchase"}>Purchase</MenuItem>
          </Select>
          {errorType && (
            <div
              className="text-danger text-start ms-3 my-1"
              style={{ fontSize: "12px" }}
            >
              {errorType}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BidDuration;
