import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { FileUploader } from "react-drag-drop-files";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

function AddSlider() {
  const nav = useNavigate();
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const [request, setRequest] = useState(false);
  const [imagee, setImagee] = useState(null);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(null);
  const [valA, setValA] = useState(null);
  const [openA, setOpenA] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Enter value");
  const [errorTitleAr, setErrorTitleAr] = useState("Enter value");
  const [errorType, setErrorType] = useState("Enter value");
  const [errorAuc, setErrorAuc] = useState("Enter value");
  const [theme, setTheme] = useState("");
  const [fullData, setFullData] = useState(null);
  const [openErr, setOpenErr] = useState(false);
  const [sliders, setSliders] = useState(null);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(()=>{
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((result) => {
        axiosInstance
          .get("/modules/auction")
          .then((res) => {
            setSliders(res.data.data.data);
            setTimeout(() => {
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  const handleChange = (files) => {
    if (files !== undefined) {
      const fileReader = new FileReader();
      let image = document.createElement("img");
      image.src = URL.createObjectURL(files);
      setImagee(image.src);
      setFullData({
        ...fullData,
        image: files,
      });
      fileReader.readAsDataURL(files);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleOpenA = () => {
    setOpenA(true);
  };

  const handleCloseErr = () => {
    setOpenErr(false);
  };
  function handleDisable() {
    if (fullData?.type === "offer") {
      if (
        (fullData?.title?.ar &&
          fullData?.title?.en &&
          fullData?.type &&
          fullData?.image) ||
        request
      )
        return false;
      else return true;
    } else {
      if (
        (fullData?.title?.ar &&
          fullData?.title?.en &&
          fullData?.type &&
          fullData?.auction_id &&
          fullData?.image) ||
        request
      )
        return false;
      else return true;
    }
  }
  return (
    <>
      <div
        style={{ width: "100%", minHeight: "100vh" }}
        className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
      >
        <div className=" container px-5">
          <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
            Add Slider
          </h2>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                English Title
              </InputLabel>
              <TextField
                id="outlined-basic"
                label="English Title"
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                name="title"
                fullWidth
                error={errorTitle}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setErrorTitle("Enter title");
                  } else if (e.target.value.length < 2) {
                    setErrorTitle("Title length must be at least 2");
                  } else {
                    setErrorTitle("");
                    setFullData({
                      ...fullData,
                      title: {
                        ...fullData?.title,
                        en: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorTitle && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorTitle}
                </div>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Arabic Title
              </InputLabel>
              <TextField
                id="outlined-basic"
                label="Arabic Title"
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                name="title"
                fullWidth
                error={errorTitleAr}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setErrorTitleAr("Enter title");
                  } else if (e.target.value.length < 2) {
                    setErrorTitleAr("Title length must be at least 2");
                  } else {
                    setErrorTitleAr("");
                    setFullData({
                      ...fullData,
                      title: {
                        ...fullData.title,
                        ar: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorTitleAr && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorTitleAr}
                </div>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Choose Type
              </InputLabel>
              <Select
                fullWidth
                sx={{ backgroundColor: "white" }}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={val}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setErrorType("Choose Type");
                  } else {
                    setVal(e.target.value);
                    setFullData({
                      ...fullData,
                      type: e.target.value,
                    });
                    setErrorType("");
                  }
                }}
              >
                <MenuItem value={"offer"}>Offer</MenuItem>
                <MenuItem value={"auction"}>Auction</MenuItem>
              </Select>
              {errorType && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorType}
                </div>
              )}
            </Grid>
            <Grid sm={6} xs={12}>
              {val === "auction" && (
                <div style={{ marginTop: 30, marginLeft: 32 }}>
                  <InputLabel
                    style={{
                      textAlign: "start",
                      marginBottom: 14,
                      fontSize: 20,
                      color: "grey",
                    }}
                  >
                    Choose Auction
                  </InputLabel>
                  <Select
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                    open={openA}
                    onClose={handleCloseA}
                    onOpen={handleOpenA}
                    value={valA}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setErrorType("Choose Auction");
                      } else {
                        setValA(e.target.value);
                        setFullData({
                          ...fullData,
                          auction_id: e.target.value,
                        });
                        setErrorAuc("");
                      }
                    }}
                  >
                    {sliders?.map((auc, idx) => {
                      return <MenuItem value={auc?.id}>{auc?.title}</MenuItem>;
                    })}
                  </Select>
                  {errorAuc && (
                    <div
                      className="text-danger text-start ms-3 my-1"
                      style={{ fontSize: "12px" }}
                    >
                      {errorAuc}
                    </div>
                  )}
                </div>
              )}
            </Grid>
            <Grid
              item
              sm={6} xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <div className="">
                <div className="" style={{ color: "grey", fontSize: 20 }}>
                  Icon
                </div>
              </div>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                name="file"
                onChange={(e) => handleChange(e.target.files[0])}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              {imagee && (
                <div
                  className=" d-flex justify-content-center mt-5"
                  style={{ height: "150px", overflow: "hidden" }}
                >
                  <img
                    alt={"idd"}
                    src={imagee}
                    width={"auto"}
                    height={"150px"}
                    className=" border border-1 rounded-2"
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <div
            className=" d-flex justify-content-end gap-5"
            style={{ marginTop: 100 }}
          >
            <Button
              disabled={handleDisable() || request}
              variant="contained"
              style={{ width: 100 }}
              onClick={(e) => {
                setRequest(true);
                if (val === "auction") {
                  if (
                    errorAuc === "" &&
                    errorTitle === "" &&
                    errorTitleAr === "" &&
                    errorType === ""
                  ) {
                    const form = new FormData();
                    for (let dataKey in fullData) {
                      if (dataKey === "title") {
                        for (let previewKey in fullData[dataKey]) {
                          form.append(
                            `title[${previewKey}]`,
                            fullData[dataKey][previewKey]
                          );
                        }
                      } else {
                        form.append(dataKey, fullData[dataKey]);
                      }
                    }
                    axios.defaults.withCredentials = true;
                    axios.defaults.withXSRFToken = true;
                    axios
                      .get("https://api.2ladue.com/sanctum/csrf-cookie")
                      .then((response) => {
                        axiosInstance
                          .post("modules/home-slider", form)
                          .then((res) => {
                            setRequest(false);
                            nav("/sliders");
                          })
                          .catch((err) => {
                            console.log(err);
                            swal("Something went wrong");
                            setRequest(false);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        swal("Something went wrong");
                        setRequest(false);
                      });
                  } else {
                    setOpenErr(true);
                  }
                } else {
                  if (
                    errorTitle === "" &&
                    errorTitleAr === "" &&
                    errorType === ""
                  ) {
                    const form = new FormData();
                    for (let dataKey in fullData) {
                      if (dataKey === "title") {
                        for (let previewKey in fullData[dataKey]) {
                          form.append(
                            `title[${previewKey}]`,
                            fullData[dataKey][previewKey]
                          );
                        }
                      } else {
                        form.append(dataKey, fullData[dataKey]);
                      }
                    }
                    axios.defaults.withCredentials = true;
                    axios.defaults.withXSRFToken = true;
                    axios
                      .get("https://api.2ladue.com/sanctum/csrf-cookie")
                      .then((response) => {
                        axiosInstance
                          .post("modules/home-slider", form)
                          .then((res) => {
                            setRequest(false);
                            nav("/sliders");
                          })
                          .catch((err) => {
                            console.log(err);
                            swal("Something went wrong");
                            setRequest(false);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        swal("Something went wrong");
                        setRequest(false);
                      });
                  } else {
                    setOpenErr(true);
                  }
                }
              }}
            >
              <div className=" d-flex justify-content-center w-100 align-items-center">
                <span>
                  {request ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Add"
                  )}
                </span>
              </div>
            </Button>
          </div>
        </div>
        <Dialog
          open={openErr}
          // TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={handleCloseErr}
          aria-describedby="alert-dialog-slide-description1"
        >
          <DialogTitle>
            <p>{"Missing Data"}</p>
            <Divider sx={{ borderColor: "black" }} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description1">
              You have left empty fields
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErr}>Back</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default AddSlider;
