import React, { useEffect, useState } from "react";
import ServerTable from "../components/ServerTable";
import { prodsCol } from "../components/columns";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import loader from "../assets/001 EN.gif";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import "../styles/inputStyle.css";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

function Products() {
  const nav = useNavigate();
  const [theme, setTheme] = useState("");
  const [reload, setReload] = useState(false);
  const columns = prodsCol(productClick, editProduct, confirmDeleteProduct);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("all");
  const [selected, setSelected] = useState(null);
  const [val, setVal] = useState("");
  const [delet, setDelet] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [products, setProducts] = useState(null);
  function productClick(id) {
    nav(`/product/${id}`);
  }
  function editProduct(id) {
    nav(`/editproduct/${id}`);
  }
  function handleClose() {
    setOpenDelete(false);
  }
  function confirmDeleteProduct(id) {
    setOpenDelete(true);
    setDeleteID(id);
  }
  function deleteProduct() {
    setOpenDelete(false);
    setDelet(true);
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .delete(`modules/product/${deleteID}`)
          .then((res) => {
            swal("Product deleted successfully");
            setDelet(false);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
            setDelet(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setDelet(false);
      });
  }
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get("modules/product")
          .then((res) => {
            setProducts(res.data.data.data);
            setSelected(res.data.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response?.status !== 404 &&
              err.response?.data?.message !== "No Data Found"
            ) {
              swal("Something went wrong");
            }
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [reload]);
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <>
          {delet && (
            <div className="sticky-top position-absolute h-100 w-100 z-3 d-flex justify-content-center align-items-center">
              <CircularProgress size={75} />
            </div>
          )}
          <div
            style={{ width: "100%", minHeight: "100vh" }}
            className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          >
            <div className="container">
              <ServerTable
                title="Products"
                type={type}
                setType={setType}
                data={selected ? selected : []}
                columns={columns}
                search={
                  <div className="search-box">
                    <button className="btn-search">
                      <SearchIcon />
                    </button>
                    <input
                      type="text"
                      className="input-search"
                      placeholder="Search"
                      value={val}
                      onChange={(e) => {
                        setVal(e.target.value);
                        if (e.target.value === "") {
                          setSelected(products);
                        } else {
                          const filtered = products?.filter((item, index) => {
                            return item.title
                              .toLowerCase()
                              .includes(e.target.value);
                          });
                          setSelected(filtered);
                        }
                      }}
                    />
                  </div>
                }
                filters={["All", "Digital", "Physical"]}
                addButton={
                  <Button
                    variant="contained"
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => nav("/addproduct")}
                  >
                    <AddIcon style={{ marginRight: 5 }} /> {"Add Product"}
                  </Button>
                }
              />
            </div>
          </div>
        </>
      )}
      <Dialog
        open={openDelete}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <p>{"Delete Product"}</p>
          <Divider sx={{ borderColor: "black" }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={deleteProduct}
            disabled={delet}
            color="error"
          >
            {delet ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Products;
