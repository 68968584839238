import React, { useEffect, useState } from "react";
import profile from "../assets/PI.png";
import ReactSelect, { components } from "react-select";
import "../styles/profile-menu.css";
import DarkModeIcon from "@mui/icons-material/DarkMode";
// import SideList from "./SideList";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import logo from "../assets/2 Ladue-08.png";
import { useDispatch, useSelector } from "react-redux";
import { nightAction, selectTab } from "../redux/actions/DayAndNightAction";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import "../styles/responsive.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

function NavbarAndList() {
  const dispatch = useDispatch();
  const menuItems = [
    // ["Home", <HomeOutlinedIcon />],
    ["Sliders", <LinearScaleOutlinedIcon />],
    ["Categories", <CategoryOutlinedIcon />],
    ["Products", <PhoneIphoneOutlinedIcon />],
    ["Customers", <GroupsOutlinedIcon />],
    ["Auctions", <CampaignOutlinedIcon />],
    ["Packages", <RedeemOutlinedIcon />],
  ];
  const nav = useNavigate();
  const [theme, setTheme] = useState(null);
  const [index, setIndex] = useState(null);
  const themeMode = localStorage.getItem("theme");
  const cookies = document.cookie;
  const arrayCookies = cookies.split(";");
  let token = "";
  for (let i = 0; i < arrayCookies.length; i++) {
    if (arrayCookies[i].includes("access_token_2ladue=")) {
      token = arrayCookies[i].split("=");
    }
  }
  // const tabStatus = useSelector((state) => {
  //    state.selectTabReducer);
  //   return state.selectTabReducer;
  // });
  let taba = localStorage.getItem("selected_tab");
  useEffect(() => {
    setIndex(Number(taba));
  }, [index, taba]);
  const adminData = JSON.parse(localStorage.getItem("adm2ladue"));
  useEffect(() => {
    setTheme(themeMode);
  }, []);
  function changeTheme() {
    if (!themeMode) {
      const res = dispatch(nightAction("Day"));
      setTheme(res.type);
    } else if (themeMode === "Day") {
      const res = dispatch(nightAction("Night"));
      setTheme(res.type);
    } else if (themeMode === "Night") {
      const res = dispatch(nightAction("Day"));
      setTheme(res.type);
    }
  }
  function handleClick(e, idx) {
    // let x = document.getElementsByTagName("p");
    // for (let i = 1; i < x.length; i++) {
    //   x[i].removeAttribute("class");
    // }
    // e.target.setAttribute("class", "text-primary fw-semibold");
    // let z = e.target.attributes.value.value.toLowerCase()
    //  z);
    setIndex(idx);
    localStorage.setItem("selected_tab", idx);
    // const res = dispatch(selectTab(idx.toString()))
    let z = e.toLowerCase();
    nav(`/${z}`);
  }
  const profileMenuSelectStyle = {
    control: (styles) => ({
      ...styles,
      borderRadius: "111rem",
      borderColor: "#fff",
      border: 0,
      boxShadow: "none",
      backgroundColor: `${theme === "Night" ? "#0d0e12" : "white"}`,
      "&:hover": {
        borderColor: "#fff",
      },
    }),
    menu: (styles) => ({
      ...styles,
      width: "18rem",
      borderColor: "#fff",
      borderRadius: "0.5rem",
      right: "25%",
    }),
  };
  return (
    <>
      {token ? (
        <nav
          className="navbar sticky-top z-10 border-bottom"
          style={{
            backgroundColor: `${theme === "Night" ? "#0d0e12" : "white"}`,
          }}
        >
          <div className="container-fluid  d-flex justify-content-between">
            <div className="nav-width navbar-toggle">
              <button
                className="btn btn-outline-secondary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                {/* <span className="navbar-toggler-icon" /> */}
                <MenuIcon />
              </button>
              <div
                className="offcanvas offcanvas-start"
                tabindex="-1"
                data-bs-scroll="true"
                id="offcanvasTop"
                aria-labelledby="offcanvasTopLabel"
                style={{
                  backgroundColor: `${theme === "Night" ? "#0d0e12" : "white"}`,
                  width: "35%"
                }}
              >
                <div className="offcanvas-header">
                  <IconButton
                    type="button"
                    className=""
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <CloseIcon sx={{color: "grey"}} />
                  </IconButton>
                </div>
                <div className="w-100 d-flex justify-content-start">
                  <Tabs orientation="vertical" className="d-flex justify-content-start gap-5">
                    {menuItems.map((item, idx) => {
                      return (
                        <Tab
                          onClick={() => handleClick(item[0], idx)}
                          key={idx}
                          label={
                            <div
                              className="d-flex justify-content-center flex-column align-items-center fw-normal gap-1"
                              style={{ fontSize: "0.7rem" }}
                              indicatorcolor="secondary"
                              textcolor="inherit"
                            >
                              <p
                                className=""
                                style={{ marginBottom: 0, color: "#2e8bf2" }}
                              >
                                {item[1]}
                              </p>
                              <p
                                value={item[0]}
                                style={{
                                  marginBottom: 0,
                                  color: index === idx ? "#2e8bf2" : "grey",
                                }}
                              >
                                {item[0]}
                              </p>
                            </div>
                          }
                        />
                      );
                    })}
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="nav-width d-flex justify-content-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                nav("/dashboard");
                localStorage.setItem("selected_tab", 9);
              }}
            >
              <img alt="logo" src={logo} width={50} height={50} />
            </div>
            <div
              className="navbar-tabs nav-width"
              style={{ color: `${theme === "Night" ? "white" : "black"} ` }}
            >
              <Tabs className="d-flex justify-content-start gap-5">
                {menuItems.map((item, idx) => {
                  return (
                    <Tab
                      onClick={() => handleClick(item[0], idx)}
                      key={idx}
                      label={
                        <div
                          className=" d-flex justify-content-center flex-column align-items-center fw-normal gap-1"
                          style={{ fontSize: "0.7rem" }}
                          indicatorcolor="secondary"
                          textcolor="inherit"
                        >
                          <p
                            className=""
                            style={{ marginBottom: 0, color: "#2e8bf2" }}
                          >
                            {item[1]}
                          </p>
                          <p
                            value={item[0]}
                            style={{
                              marginBottom: 0,
                              color: index === idx ? "#2e8bf2" : "grey",
                            }}
                          >
                            {item[0]}
                          </p>
                        </div>
                      }
                    />
                  );
                })}
              </Tabs>
            </div>
            <div className="d-flex justify-content-end align-items-center gap-4 nav-width">
              <div onClick={changeTheme} style={{ cursor: "pointer" }}>
                <DarkModeIcon
                  style={{ color: `${theme === "Night" ? "#e2e2e2" : "grey"}` }}
                />
              </div>
              <ReactSelect
                placeholder={
                  <img
                    src={adminData?.image ? adminData?.image : profile}
                    width={40}
                    height={40}
                    alt="profile_image"
                    style={{ cursor: "pointer" }}
                    className="rounded-5"
                  />
                }
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Menu: (props) => (
                    <components.Menu {...props} className="profile-menu" />
                  ),
                }}
                isSearchable={false}
                value={""}
                styles={profileMenuSelectStyle}
                menuPosition="fixed"
                options={[
                  {
                    label: (
                      <>
                        <div
                          className="d-flex justify-content-start gap-4 align-items-center pb-3 border-bottom"
                          onClick={() => {
                            nav("/profile");
                            localStorage.setItem("selected_tab", 9);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={adminData?.image ? adminData?.image : profile}
                            alt="profile_image"
                            width={50}
                            height={50}
                          />
                          <div className="d-flex flex-column gap-1">
                            <div
                              className="fw-semibold"
                              style={{ fontSize: "0.65rem" }}
                            >
                              {adminData?.name}
                            </div>
                            <div
                              className="text-secondary"
                              style={{ fontSize: "0.5rem" }}
                            >
                              {adminData?.email}
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                    value: "profile",
                  },
                  {
                    label: (
                      <>
                        <div
                          className="text-secondary"
                          style={{ fontSize: "0.75rem", cursor: "pointer" }}
                          onClick={() => {
                            nav("/settings");
                            localStorage.setItem("selected_tab", 9);
                          }}
                        >
                          Settings
                        </div>
                      </>
                    ),
                    value: "settings",
                  },
                  {
                    label: (
                      <>
                        <div
                          className="text-secondary"
                          style={{ fontSize: "0.75rem", cursor: "pointer" }}
                          onClick={() => {
                            nav("/deleteaccount");
                            localStorage.setItem("selected_tab", 10);
                          }}
                        >
                          Delete account
                        </div>
                      </>
                    ),
                    value: "delete account",
                  },
                  {
                    label: (
                      <>
                        <div
                          className="text-secondary"
                          onClick={() => {
                            document.cookie = `access_token_2ladue=${token};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                            localStorage.setItem("selected_tab", 9);
                            localStorage.removeItem("adm2ladue");
                            nav("/");
                          }}
                          style={{ fontSize: "0.75rem", cursor: "pointer" }}
                        >
                          Sign out
                        </div>
                      </>
                    ),
                    value: "sign out",
                  },
                ]}
              />
            </div>
          </div>
        </nav>
      ) : (
        <nav
          className="navbar sticky-top z-10 border-bottom"
          style={{
            backgroundColor: `${theme === "Night" ? "#0d0e12" : "white"}`,
          }}
        >
          <div className="container-fluid  d-flex justify-content-between">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => nav("/dashboard")}
            >
              <img alt="logo" src={logo} width={50} height={50} />
            </div>
            <div className="d-flex justify-content-end align-items-center gap-4">
              <div onClick={changeTheme}>
                <DarkModeIcon
                  style={{ color: `${theme === "Night" ? "#e2e2e2" : "grey"}` }}
                />
              </div>
              <ReactSelect
                placeholder={
                  <IconButton style={{height: 60}}>
                    <MoreVertIcon />
                  </IconButton>
                }
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Menu: (props) => (
                    <components.Menu {...props} className="profile-menu" />
                  ),
                }}
                isSearchable={false}
                value={""}
                styles={profileMenuSelectStyle}
                menuPosition="fixed"
                options={[
                  {
                    label: (
                      <>
                        <div
                          className="text-secondary"
                          style={{ fontSize: "0.75rem", cursor: "pointer" }}
                          onClick={() => {
                            nav("/");
                            localStorage.setItem("selected_tab", 9);
                          }}
                        >
                          Login
                        </div>
                      </>
                    ),
                    value: "login",
                  },
                  {
                    label: (
                      <>
                        <div
                          className="text-secondary"
                          style={{ fontSize: "0.75rem", cursor: "pointer" }}
                          onClick={() => {
                            nav("/deleteaccount");
                            localStorage.setItem("selected_tab", 10);
                          }}
                        >
                          Delete account
                        </div>
                      </>
                    ),
                    value: "delete account",
                  },
                ]}
              />
            </div>
          </div>
        </nav>
      )}
      {/* <SideList /> */}
    </>
  );
}

export default NavbarAndList;
