import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Products from './pages/Products';
import { Route, Routes } from 'react-router-dom';
import NavbarAndList from './components/NavbarAndList';
import Product from './pages/Product';
import Profile from './pages/Profile';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import AddProduct from './pages/AddProduct';
import Categories from './pages/Categories';
import Sliders from './pages/Sliders';
import Slider from './pages/Slider';
import AddSlider from './pages/AddSlider';
import ResetPassword from './pages/ResetPassword';
import EditProduct from './pages/EditProduct';
import EditSlider from './pages/EditSlider';
import Category from './pages/Category';
import AddCategory from './pages/AddCategory';
import EditCategory from './pages/EditCategory';
import Auctions from './pages/Auctions';
import Auction from './pages/Auction';
import AddAuction from './pages/AddAuction';
import EditAuction from './pages/EditAuction';
import Packages from './pages/Packages';
import AddPackage from './pages/AddPackage';
import EditPackage from './pages/EditPackagee';
import Package from './pages/Package';
import Settings from './pages/Settings';
import ReqAuth from './components/ReqAuth';
import Setting from './pages/Setting';
import EditSetting from './pages/EditSetting';
import DashboardHome from './pages/DashboardHome';
import DeleteAccount from './pages/DeleteAccount';

function App() {
  return (
    <>
      <NavbarAndList />
      <Routes>
        <Route index path='/' element={<Login />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/resetpassword' element={<ResetPassword />} />
        <Route path='/deleteaccount' element={<DeleteAccount />} />
        <Route element={<ReqAuth />}>
          <Route path='/dashboard' element={<DashboardHome />} />
          <Route path='/categories' element={<Categories />} />
          <Route path='/category/:caid' element={<Category />} />
          <Route path='/products' element={<Products />} />
          <Route path='/product/:pid' element={<Product />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/customers' element={<Customers />} />
          <Route path='/customer/:cid' element={<Customer />} />
          <Route path='/addproduct' element={<AddProduct />} />
          <Route path='/editproduct/:pid' element={<EditProduct />} />
          <Route path='/sliders' element={<Sliders />} />
          <Route path='/slider/:sid' element={<Slider />} />
          <Route path='/addslider' element={<AddSlider />} />
          <Route path='/addcategory' element={<AddCategory />} />
          <Route path='/editslider/:sid' element={<EditSlider />} />
          <Route path='/editcategory/:caid' element={<EditCategory />} />
          <Route path='/auctions' element={<Auctions />} />
          <Route path="/auction/:aid" element={<Auction />} />
          <Route path='/editauction/:aid' element={<EditAuction />} />
          <Route path='/addauction' element={<AddAuction />} />
          <Route path='/packages' element={<Packages />} />
          <Route path='/package/:paid' element={<Package />} />
          <Route path='/addpackage' element={<AddPackage />} />
          <Route path='/editpackage/:paid' element={<EditPackage />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/setting/:setid' element={<Setting />} />
          <Route path='/editsetting/:setid' element={<EditSetting />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;