import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { FileUploader } from "react-drag-drop-files";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import loader from "../assets/001 EN.gif";
import CircularProgress from '@mui/material/CircularProgress';

function EditSlider() {
  const url = useLocation();
  const nav = useNavigate();
  const prodid = url.pathname.split("/");
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const [request, setRequest] = useState(false)
  const [imagee, setImagee] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [titleVal, setTitleVal] = useState(null)
  const [titleValAr, setTitleValAr] = useState(null)
  const [val, setVal] = useState(null);
  const [valA, setValA] = useState(null);
  const [openA, setOpenA] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorTitleAr, setErrorTitleAr] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorAuc, setErrorAuc] = useState("");
  const [theme, setTheme] = useState("");
  const [fullData, setFullData] = useState(null);
  const [openErr, setOpenErr] = React.useState(false);
  const [sliders, setSliders] = useState(null);
  const [loading, setLoading] = useState(true);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get(`/modules/home-slider/${prodid[prodid.length - 1]}`)
          .then((res) => {
            setTitleVal(res.data.data.title_en)
            setTitleValAr(res.data.data.title_ar)
            setVal(res.data.data.type)
            setValA(res.data.data.auction)
            setImagee(res.data.data?.image)
            setFullData({
              title: {
                ar: res.data.data.title_ar,
                en: res.data.data.title_en
              },
              type: res.data.data.type,
              auction_id: res.data.data.auction,
              // image: res.data.data?.image,
            })
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
      });
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((result) => {
        axiosInstance
          .get("/modules/auction")
          .then((res) => {
            setSliders(res.data.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleChange = (files) => {
    if (files !== undefined) {
      const fileReader = new FileReader();
      let image = document.createElement("img");
      image.src = URL.createObjectURL(files);
      setImagee(image.src);
      setFullData({
        ...fullData,
        image: files,
      });
      fileReader.readAsDataURL(files);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleOpenA = () => {
    setOpenA(true);
  };

  const handleCloseErr = () => {
    setOpenErr(false);
  };
  function handleDisable(){
    if(fullData?.type === "offer"){
      if((fullData?.title?.ar && fullData?.title?.en && fullData?.type) || request)return false
      else return true
    } else {
      if((fullData?.title?.ar && fullData?.title?.en && fullData?.type && fullData?.auction_id) || request)return false
      else return true
    }
  }
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className=" container px-5">
            <div className="p-5">
              <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
                Edit Slider
              </h2>
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <InputLabel
                    style={{
                      textAlign: "start",
                      marginBottom: 14,
                      fontSize: 20,
                      color: "grey",
                    }}
                  >
                    English Title
                  </InputLabel>
                  <TextField
                    value={titleVal}
                    id="outlined-basic"
                    label="English Title"
                    variant="outlined"
                    sx={{ backgroundColor: "white" }}
                    name="title"
                    fullWidth
                    error={errorTitle}
                    onChange={(e) => {
                      setTitleVal(e.target.value)
                      if (e.target.value === "") {
                        setErrorTitle("Enter title");
                      } else if (e.target.value.length < 2) {
                        setErrorTitle("Title length must be at least 2");
                      } else {
                        setErrorTitle("");
                        setFullData({
                          ...fullData,
                          title: {
                            ...fullData?.title,
                            en: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                  {errorTitle && (
                    <div
                      className="text-danger text-start ms-3 my-1"
                      style={{ fontSize: "12px" }}
                    >
                      {errorTitle}
                    </div>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel
                    style={{
                      textAlign: "start",
                      marginBottom: 14,
                      fontSize: 20,
                      color: "grey",
                    }}
                  >
                    Arabic Title
                  </InputLabel>
                  <TextField
                    value={titleValAr}
                    id="outlined-basic"
                    label="Arabic Title"
                    variant="outlined"
                    sx={{ backgroundColor: "white" }}
                    name="title"
                    fullWidth
                    error={errorTitleAr}
                    onChange={(e) => {
                      setTitleValAr(e.target.value)
                      if (e.target.value === "") {
                        setErrorTitleAr("Enter title");
                      } else if (e.target.value.length < 2) {
                        setErrorTitleAr("Title length must be at least 2");
                      } else {
                        setErrorTitleAr("");
                        setFullData({
                          ...fullData,
                          title: {
                            ...fullData?.title,
                            ar: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                  {errorTitleAr && (
                    <div
                      className="text-danger text-start ms-3 my-1"
                      style={{ fontSize: "12px" }}
                    >
                      {errorTitleAr}
                    </div>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel
                    style={{
                      textAlign: "start",
                      marginBottom: 14,
                      fontSize: 20,
                      color: "grey",
                    }}
                  >
                    Choose Type
                  </InputLabel>
                  <Select
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={val}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setErrorType("Choose Type");
                      } else {
                        setVal(e.target.value);
                        setFullData({
                          ...fullData,
                          type: e.target.value,
                        });
                        setErrorType("");
                      }
                    }}
                  >
                    <MenuItem value={"offer"}>Offer</MenuItem>
                    <MenuItem value={"auction"}>Auction</MenuItem>
                  </Select>
                  {errorType && (
                    <div
                      className="text-danger text-start ms-3 my-1"
                      style={{ fontSize: "12px" }}
                    >
                      {errorType}
                    </div>
                  )}
                </Grid>
                <Grid sm={6} xs={12}>
                  {val === "auction" && (
                    <div style={{ marginTop: 30, marginLeft: 32 }}>
                      <InputLabel
                        style={{
                          textAlign: "start",
                          marginBottom: 14,
                          fontSize: 20,
                          color: "grey",
                        }}
                      >
                        Choose Auction
                      </InputLabel>
                      <Select
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        open={openA}
                        onClose={handleCloseA}
                        onOpen={handleOpenA}
                        value={valA}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setErrorType("Choose Auction");
                          } else {
                            setValA(e.target.value);
                            setFullData({
                              ...fullData,
                              auction_id: e.target.value,
                            });
                            setErrorAuc("");
                          }
                        }}
                      >
                        {sliders?.map((auc, idx)=>{
                        return(
                          <MenuItem value={auc?.id}>{auc?.title}</MenuItem>
                        )
                      })}
                      </Select>
                      {errorAuc && (
                        <div
                          className="text-danger text-start ms-3 my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {errorAuc}
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  sm={6} xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <div className="">
                    <div className="" style={{ color: "grey", fontSize: 20 }}>
                      Icon
                    </div>
                  </div>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    name="file"
                    onChange={(e) => handleChange(e.target.files[0])}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {imagee && (
                    <div
                      className=" d-flex justify-content-center mt-5"
                      style={{ height: "150px", overflow: "hidden" }}
                    >
                      <img
                        alt={"idd"}
                        src={imagee}
                        width={"auto"}
                        height={"150px"}
                        className=" border border-1 rounded-2"
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              <div
                className=" d-flex justify-content-end gap-5"
                style={{ marginTop: 100 }}
              >
                <Button
                  disabled={handleDisable() || request}
                  variant="contained"
                  style={{ width: 100 }}
                  onClick={(e) => {
                    setRequest(true)
                    if (val === "auction") {
                      if (
                        errorAuc === "" &&
                        errorTitle === "" &&
                        errorTitleAr === "" &&
                        errorType === ""
                      ) {
                        const form = new FormData();
                        for (let dataKey in fullData) {
                          if (dataKey === "title") {
                            for (let previewKey in fullData[dataKey]) {
                              form.append(
                                `title[${previewKey}]`,
                                fullData[dataKey][previewKey]
                              );
                            }
                          } else {
                            form.append(dataKey, fullData[dataKey]);
                          }
                        }
                        form.append("_method", "PATCH");
                        axios.defaults.withCredentials = true;
                        axios.defaults.withXSRFToken = true;
                        axios
                          .get("https://api.2ladue.com/sanctum/csrf-cookie")
                          .then((response) => {
                            axiosInstance
                              .post(
                                `modules/home-slider/${
                                  prodid[prodid.length - 1]
                                }`,
                                form
                              )
                              .then((res) => {
                                swal("Slider edited successfully");
                                setRequest(false);
                                nav("/sliders");
                              })
                              .catch((err) => {
                                console.log(err);
                                swal("Something went wrong");
                                setRequest(false);
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                            swal("Something went wrong");
                            setRequest(false);
                          });
                      } else {
                        setOpenErr(true);
                      }
                    } else {
                      if (
                        errorTitle === "" &&
                        errorTitleAr === "" &&
                        errorType === ""
                      ) {
                        const form = new FormData();
                        for (let dataKey in fullData) {
                          if (dataKey === "title") {
                            for (let previewKey in fullData[dataKey]) {
                              form.append(
                                `title[${previewKey}]`,
                                fullData[dataKey][previewKey]
                              );
                            }
                          } else {
                            form.append(dataKey, fullData[dataKey]);
                          }
                        }
                        form.append("_method", "PATCH");
                        axios.defaults.withCredentials = true;
                        axios.defaults.withXSRFToken = true;
                        axios
                          .get("https://api.2ladue.com/sanctum/csrf-cookie")
                          .then((response) => {
                            axiosInstance
                              .post(
                                `modules/home-slider/${
                                  prodid[prodid.length - 1]
                                }`,
                                form
                              )
                              .then((res) => {
                                swal("Slider edited successfully");
                                setRequest(false);
                                nav("/sliders");
                              })
                              .catch((err) => {
                                console.log(err);
                                swal("Something went wrong");
                                setRequest(false);
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                            swal("Something went wrong");
                            setRequest(false);
                          });
                      } else {
                        setOpenErr(true);
                      }
                    }
                  }}
                >
                  <div className=" d-flex justify-content-center w-100 align-items-center">
                    <span>{request ? <CircularProgress size={14} sx={{color: "white"}} /> : "Edit"}</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <Dialog
            open={openErr}
            // TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="sm"
            onClose={handleCloseErr}
            aria-describedby="alert-dialog-slide-description1"
          >
            <DialogTitle>
              <p>{"Missing Data"}</p>
              <Divider sx={{ borderColor: "black" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description1">
                You have left empty fields
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErr}>Back</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default EditSlider;
