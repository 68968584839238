import React, { useState, useEffect } from "react";
import logo from "../assets/New Colors_2.gif";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from 'axios';

function ForgotPassword() {
  const [theme, setTheme] = useState("");
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const loginFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Invalid email format").required("Enter your name"),
    }),
    onSubmit: (values) => {
      const form = new FormData();
      form.append("email", values.email);
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      axios.get("https://api.2ladue.com/sanctum/csrf-cookie").then((response) => {
      axiosInstance.post('/auth/forget', form).then((res)=>{
        swal("If your email registered, you will receive email to reset your password")
      }).catch((err)=>{
        console.log(err)
        swal("Something went wrong")
      }).catch((err) => {
        console.log(err);
        swal("Something went wrong");
      });
  })
    },
  });
  return (
    <div
      className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
      style={{ width: "100%", height: "100vh" }}
    >
      <div
        className=" d-flex justify-content-between rounded-4 p-4"
        style={{ marginLeft: "150px", marginRight: "150px", backgroundColor: "#fcfcfb"  }}
      >
        <div className="w-100 text-center py-4">
          <img alt="logo" src={logo} width={350} height={600} />
        </div>
        <div className=" w-100 text-center d-flex justify-content-center flex-column rounded-3">
          <div className="fs-4 fw-semibold mb-5">Forgot Password</div>
          <form
            onSubmit={loginFormik.handleSubmit}
            encType="multipart/form-data"
            className=""
            style={{ paddingLeft: "100px", paddingRight: "100px" }}
          >
            <div className="mb-2">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                error={loginFormik?.errors?.email}
                fullWidth
                helperText={loginFormik?.errors?.email}
                onChange={loginFormik.handleChange}
                onBlur={loginFormik.handleBlur}
                value={loginFormik.values.email}
              />
            </div>
            <div className="mb-5 d-flex justify-content-end">
               <Link to={'/'}>Login</Link>
            </div>
            <button className="btn btn-primary w-100" type="submit">
                Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;