import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

function TitleImage({
  title,
  setFullData,
  fullData,
  setErrorTitle,
  errorTitle,
  setErrorTitleAr,
  errorTitleAr,
  titl,
  titleAr,
  auc,
  setTitle,
  setTitleAr,
}) {
  const [imagee, setImagee] = useState(null);
  const fileTypes = ["JPG", "PNG", "JPEG"];
  useEffect(()=>{
    if(auc){
      setImagee(auc.image)
    }
  }, [])
  const handleChangeCover = (files) => {
    setFullData({
      ...fullData,
      cover: files,
    });
    if (files !== undefined) {
      const fileReader = new FileReader();
      let image = document.createElement("img");
      image.src = URL.createObjectURL(files);
      setImagee(image.src);
      fileReader.readAsDataURL(files);
    }
  };
  return (
    <>
      <div className="">
        <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
          {title}
        </h2>
        <div className=" d-flex flex-column mb-5">
          <div className="view-page d-flex justify-content-between mb-5 w-100">
            <div className="w-100 text-center px-5">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                English Title
              </InputLabel>
              <TextField
                value={titl}
                label="Add Title"
                variant="outlined"
                fullWidth
                sx={{ backgroundColor: "white" }}
                error={errorTitle}
                onChange={(e) => {
                  if(setTitle){
                    setTitle(e.target.value)
                  }
                  if (e.target.value === "") {
                    setErrorTitle("Enter title");
                  } else if (e.target.value.length < 2) {
                    setErrorTitle("Title length must be at least 2");
                  } else {
                    setErrorTitle("");
                    setFullData({
                      ...fullData,
                      title: {
                        ...fullData.title,
                        en: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorTitle && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorTitle}
                </div>
              )}
            </div>
            <div className="w-100 text-center px-5">
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Arabic Title
              </InputLabel>
              <TextField
                value={titleAr}
                label="Add Title"
                variant="outlined"
                fullWidth
                sx={{ backgroundColor: "white" }}
                error={errorTitleAr}
                // helperText={errorTitleAr}
                onChange={(e) => {
                  if(setTitleAr){
                    setTitleAr(e.target.value)
                  }
                  if (e.target.value === "") {
                    setErrorTitleAr("Enter title");
                  } else if (e.target.value.length < 2) {
                    setErrorTitleAr("Title length must be at least 2");
                  } else {
                    setErrorTitleAr("");
                    setFullData({
                      ...fullData,
                      title: {
                        ...fullData.title,
                        ar: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorTitleAr && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorTitleAr}
                </div>
              )}
            </div>
          </div>
          <div className="view-page d-flex justify-content-start gap-5 align-items-center">
            <div className="w-100 text-center px-5">
              <div className=" text-start mb-5" style={{ color: "grey", fontSize: 20 }}>
                Icon
              </div>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                name="file"
                onChange={(e) => handleChangeCover(e.target.files[0])}
              />
            </div>
            <div className="w-100">
              {imagee && (
                <div
                  className=" d-flex justify-content-center mt-5"
                  style={{ height: "150px", overflow: "hidden" }}
                >
                  <img
                    alt={"idd"}
                    src={imagee}
                    width={"auto"}
                    height={"150px"}
                    className=" border border-1 rounded-2"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TitleImage;
